.custom-color
{
  background-color : #046c04;
}
.btn.disabled, .btn:disabled, fieldset:disabled .btn
{
  background-color: #549c6c;
}
.voice-component-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    /* background-color: red; */
  }
  
  .chat-bubble {
    background-color: #F0F0F0;
    padding: 8px;
    border-radius: 12px;
    margin-bottom: 12px;
  }
  
  .chat-bubble-content {
    display: flex;
    align-items: center;
  }
  
  .audio-player-container {
    margin-bottom: 12px;
  }
  
  .button-container {
    display: flex;
    justify-content: center;
    gap: 8px;
  }
  .form-floating
  {
    width: 40vw;
    /* background-color: #cccccc; */
    color:black;
    justify-content: center;
    align-items: center;
  }
  .mybuttons
  {
    width:100vw;
  }
  @media (max-width: 768px) {
    .button-container {
      gap: 0px;
    }
    .button-container  button{
      font-size: .7rem;
    }
    .mybuttons
  {
    width:94vw;
  }
  }