/* NewTask.css */
.new-task-container {
    text-align: center;
    margin-top: 20px;
  }
  
  .new-task-container .button-container {
    margin-top: 10px;
  }
  
  .new-task-container .button-container button {
    margin-right: 10px;
    padding: 6px 12px;
    background-color: #046c04;
    color: #fff;
    border: none;
    cursor: pointer;
    font-size: 16px;
  }
  
  .new-task-container .button-container button:hover {
    background-color: #046c04;
  }
  
  @media (max-width: 768px) {
    .new-task-container .button-container button {
      margin-right: 1px;
      padding: px 4px;
      background-color: #046c04;
      color: #fff;
      border: none;
      cursor: pointer;
      font-size: 12px;
    }
    .new-task-container .button-container
    {
      gap:1px;
    }
  }